import { useAuthContext } from '@/lib/contexts';
import { SquadstackLoader } from '@/lib/loader';
import { useFeatureFlag } from '@/lib/utils/FeatureFlags';
import dynamic from 'next/dynamic';

const CampaignViewSme = dynamic(
  () =>
    import('@/pages/plug-n-play/campaigns/components').then(
      (mod) => mod.CampaignViewSme
    ),
  {
    loading: () => <SquadstackLoader />,
  }
);

const CampaignsView = dynamic(
  () => import('./components/CampaignsView').then((mod) => mod.CampaignsView),
  {
    loading: () => <SquadstackLoader />,
  }
);

const FirstCampaign = dynamic(
  () => import('./components/FirstCampaign').then((mod) => mod.FirstCampaign),
  {
    loading: () => <SquadstackLoader />,
  }
);

export default function Campaigns() {
  const { state } = useAuthContext();
  const featureFlags = useFeatureFlag();

  return featureFlags.show_new_campaign_flow ? (
    <CampaignViewSme />
  ) : state.user && state.user?.has_campaign ? (
    <CampaignsView />
  ) : (
    <FirstCampaign />
  );
}
